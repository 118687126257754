// @ts-nocheck
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const baseRoute = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "home" */ "@/views/home/home.vue"),
    meta: {
      title: "创世科技",
    },
  },
];

const routes = baseRoute;

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,

  // @ts-ignore
  scrollBehavior: () => ({ y: 0 }),
  routes,
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export default router;
