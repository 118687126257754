import { axios } from "../request/request";

// banner信息获取
export function getBanners() {
  return axios({
    url: "/index/index/getAllBanner",
    method: "post",
  });
}
// 动态信息获取
export function getNews() {
  return axios({
    url: "/index/index/getAllArticle/",
    method: "post",
  });
}
// 提交业务表单信息
export function commitFroms(data) {
  return axios({
    url: "/index/index/addMessage/",
    method: "post",
    data,
  });
}
