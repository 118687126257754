import Vue from "vue";
import Vuex from "vuex";
import getters from "./getters";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {},
  state: {
    msg: {
      content: "",
      type: "",
    },
    loadingMsg: {
      content: "",
      type: "",
    },
    isBlackBack: false,
  },
  mutations: {
    SET_ISSHOW(state, [content, type]) {
      state.msg.content = content;
      state.msg.type = type;
    },
    LOADING(state, [content, type]) {
      state.loadingMsg.content = content;
      state.loadingMsg.type = type;
    },
    SET_BLACK_BACK(state, isBlack) {
      state.isBlackBack = isBlack;
    },
  },
  actions: {},
  getters,
});
