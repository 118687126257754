// @ts-nocheck
import Vue from "vue";
import AOS from "aos";
import store from "./store";
import App from "./App.vue";
import router from "./router";
import api from "./api/index";
import animated from "animate.css";
import { VueAxios } from "./request/request";
import VueWechatTitle from "vue-wechat-title";
import VueAwesomeSwiper from "vue-awesome-swiper";
import "./global.less";
import "./alicon/index";
import "aos/dist/aos.css";
import "lib-flexible/flexible";
import "swiper/dist/css/swiper.css";

Vue.use(animated);
Vue.use(VueAxios);
Vue.use(VueWechatTitle);
Vue.use(VueAwesomeSwiper);

Vue.prototype.api = api;
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  mounted() {
    AOS.init({
      duration: 700,
      easing: "ease-in-sine",
      delay: 100,
      // offset: 90,
      once: true,
    });
  },
  render: (h) => h(App),
}).$mount("#app");
