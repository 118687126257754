<template>
  <!-- <transition name="fade" mode="out-in">
  </transition>-->
  <router-view v-wechat-title="$route.meta.title"></router-view>
</template>

<script>
/* eslint-disable prettier/prettier */
// eslint-disable-next-line no-unused-vars
import { deviceEnquire, DEVICE_TYPE } from "@/tool/enquire.js";
export default {
  mounted() {
    // deviceEnquire((deviceType) => {
    //   switch (deviceType) {
    //     case DEVICE_TYPE.DESKTOP:
    //       this.matchEquipment(DEVICE_TYPE.DESKTOP);
    //       break;
    //     case DEVICE_TYPE.MOBILE:
    //       this.matchEquipment(DEVICE_TYPE.MOBILE);
    //       break;
    //   }
    // });
  },
  methods: {
    matchEquipment(type) {
      console.log(type);
      /*
       * TODO
       * 先判断window.location.href是移动端还是PC端
       * 判断地址的客户端与宽度匹配的客户端（type）是否一致
       * 不一致跳转宽度匹配的客户端的链接
       */
      const isMobile = window.location.href.indexOf("m.chskj.cn") > -1;
      const isProd = process.env.NODE_ENV === "production";
      console.log(process.env.NODE_ENV);
      if (isMobile && type === "desktop" && isProd) {
        window.location.href = "https://www.chskj.cn";
      } else if (!isMobile && type === "mobile" && isProd) {
        window.location.href = "https://m.chskj.cn";
      }
    },
  },
};
</script>
<style lang="less"></style>
