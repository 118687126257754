import axios from "axios";
import { VueAxios } from "./axios";

const baseURL = "https://api.chskj.cn";
// 创建 axios 实例
const service = axios.create({
  baseURL: baseURL,
  timeout: 60000, // 请求超时时间
  headers: {},
});

// 错误结果拦截器
const err = (error) => {
  if (error.response) {
    // const data = error.response.data;
  }
  return Promise.reject(error);
};

// 请求拦截器
service.interceptors.request.use((config) => {
  return config;
}, err);

// 结果拦截器
service.interceptors.response.use((response) => {
  if (response.data.code !== 200) {
    console.log("error");
  }
  return response.data;
}, err);

const installer = {
  vm: {},
  install(Vue) {
    Vue.use(VueAxios, service);
  },
};

export { installer as VueAxios, service as axios };
